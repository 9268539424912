import React from "react";
import Menu from "../Menu";
import { Col, Container, Row, Table } from "react-bootstrap";
import { BASE_URL_API } from "../config.url";
import Pagination from "../Pagination/Pagination";
const PosicaoDeEstoque = () => {
  const [epi, setEpi] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [PageSize, setPageSize] = React.useState(10);

  React.useMemo(() => {
    fetch(`${BASE_URL_API}/posicao/estoque`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
        "x-acess-token": localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((json) => setEpi(json));
  }, []);

  if (epi !== "") {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    var currentData = epi.slice(firstPageIndex, lastPageIndex);
  }

  const dia =
    new Date().getDate() > 9
      ? new Date().getDate()
      : "0" + new Date().getDate();
  const mes =
    new Date().getMonth() > 9
      ? new Date().getMonth()
      : "0" + (new Date().getMonth() + 1);
  const ano = new Date().getFullYear();
  const dataHoje = dia + "/" + mes + "/" + ano;
  const minutos = new Date().getMinutes()
    ? new Date().getMinutes() > 9
    : "0" + new Date().getMinutes();
  const horario = new Date().getHours() + ":" + new Date().getMinutes();
  return (
    <>
      <Menu />
      <Container>
        <Row>
          <Col className="text-center mt-3" sm="12">
            <h1>Posição de estoque</h1>
            <h1>
              Data {dataHoje} hora {horario}
            </h1>
          </Col>
        </Row>

        <Row>
          <Col className="mt-3" sm="12">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>EPI</th>
                  <th className="text-center">CA</th>
                  <th className="text-center">Vlr. unitario em R$</th>
                  <th className="text-center">Quantidade</th>
                </tr>
              </thead>
              <tbody>
                {currentData &&
                  currentData.map((item, indice) => {
                    return (
                      <tr key={indice}>
                        <th>{item.desc_epi}</th>
                        <th className="text-center">{item.num_ca}</th>
                        <th className="text-center">
                          {Number(item.vl_unitario.replace(",", ".")).toFixed(
                            2
                          )}
                        </th>
                        <th className="text-center">{item.qt_estoque}</th>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Col>
          <Col sm="12">
            {currentData && (
              <Pagination
                currentPage={currentPage}
                totalCount={epi.length}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PosicaoDeEstoque;
