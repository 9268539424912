import React from "react";
import { Col, Container, NavLink, Row } from "react-bootstrap";
import { Chart } from "react-google-charts";
import Menu from "../Menu";
import GraficoDashboard from "./GraficoDashboard";
import PosicaoDeEstoque from "./PosicaoDeEstoque";
import CustoTotalFuncionarios from "./CustoTotalFuncionarios";

export const dataLine = [
  ["x", "Performance"],
  [0, 0],
  [1, 0],
  [2, 0],
  [3, 0],
  [4, 0],
  [5, 0],
  [6, 11],
  [7, 27],
  [8, 19],
  [9, 9],
  [10, 0],
  [11, 0],
  [12, 0],
];

export const optionsLine = {
  hAxis: {
    title: "",
  },
  vAxis: {
    title: "",
  },
  series: {
    1: { curveType: "function" },
  },
};

export const dataBar = [
  ["Funcionários", "JOSÉ C.", "JOSÉ S.", "CLEVERSON"],
  ["2022", 298.73, 272.93, 272.12],
];

export const optionsBar = {
  chart: {
    title: "",
    subtitle: "",
  },
};

export const dataPie = [
  ["EPI", "Quantidade"],
  ["Bota", 11],
  ["Capacete", 2],
  ["Luva", 2],
  ["Camisa", 2],
  ["Calca", 7],
];

export const optionsPie = {
  title: "",
};

const PaginaInicialDashboards = () => {
  return (
    <>
      <Menu />
      <Container fluid>
        <Row className="mt-5">
          <Col sm="12">
            <NavLink key={"1"} to="/graficos" href="/graficos">
              <h2 className="text-center ">Performance Mensal</h2>
            </NavLink>
          </Col>
          <Col sm="12">
            <Chart
              chartType="LineChart"
              width="100%"
              height="300px"
              data={dataLine}
              options={optionsLine}
            />
          </Col>
        </Row>

        <Row className="mt-5  text-center">
          <Col sm="6">
            <NavLink
              key={"2"}
              to="dashboards/funcionarios"
              href="dashboards/funcionarios"
            >
              <h3>Análise por funcionário</h3>
            </NavLink>

            <Chart chartType="Bar" data={dataBar} options={optionsBar} />
          </Col>
          <Col sm="6">
            <NavLink key={"3"} to="/posicao/estoque" href="/posicao/estoque">
              <h3>Consumo de Estoque</h3>
            </NavLink>

            <Chart chartType="PieChart" data={dataPie} options={optionsPie} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PaginaInicialDashboards;
