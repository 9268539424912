import React from "react";
import useFetch from "../Hooks/useFetch";
import { BASE_URL_API } from "../config.url";
import { Col, Container, Row } from "react-bootstrap";

const CadastroDeRisco = () => {
  return (
    <>
      <Container>
        <Row>
          <Col className="text-center mt-4">
            Cadastro De Risco <p> dados: </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CadastroDeRisco;
