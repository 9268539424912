import { Col, Container, Form, Row } from "react-bootstrap";
import Menu from "../Menu";
import style from "./FuncionarioUpload.module.css";

import AWS from 'aws-sdk';

const S3_BUCKET = 'facial-recognition-teste';
const REGION = 'us-east-1';

AWS.config.update({
  accessKeyId: 'AKIAV7NEXYHG6DIQMKZB',
  secretAccessKey: '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',
  region: REGION
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const CadastroImagem = () => {
  async function uploadImages(event){
    const promises = [];
    const files = event.target.files;
    Array.from(files).forEach(file => {
      if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
        const fileName = file.name.split(".");
        const fileNameValidate = fileName[0] + "." + fileName.pop();
        const params = {
          Bucket: S3_BUCKET,
          Key: fileNameValidate,
          ContentType: file.type,
          Body: file,
        };

        const promise = myBucket.upload(params).promise();
        promises.push(promise);
        alert("Imagens enviadas com sucesso!");
        window.location.href = "/imagens";
      } else {
        alert("Apenas arquivos de imagem são aceitos!");
      }
    });
  }

  return (
      <>
        <Menu />
        <Container className="text-center mb-3 mt-5">
          <Row>
            <Col>
              <h1>Upload de Imagens</h1>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Form className={style.formulario}>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label className="text-white">
                    Imagens dos Funcionários
                  </Form.Label>
                  <Form.Control
                    onChange={uploadImages}
                    aria-describedby="imageNameHelp"
                    type="file"
                    accept="image/*"
                    multiple
                    required
                  />
                  <Form.Text id="imageNameHelp" className="text-white">
                    <strong>Atenção!</strong> Salve os arquivos contendo a <strong>Matrícula</strong> do funcionário como nome do arquivo.
                  </Form.Text>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Container>
      </>
  )
}

export default CadastroImagem;
