// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VerificaUsuario_formulario__xwbpe {\n  background-color: rgb(34, 34, 34);\n  padding: 2%;\n  border-radius: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/Estoque/VerificaUsuario.module.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,WAAW;EACX,mBAAmB;AACrB","sourcesContent":[".formulario {\n  background-color: rgb(34, 34, 34);\n  padding: 2%;\n  border-radius: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formulario": "VerificaUsuario_formulario__xwbpe"
};
export default ___CSS_LOADER_EXPORT___;
