import React from "react";
import { Form, Row, Col, Container } from "react-bootstrap";
import Botao from "../Botao/Botao";
import style from "./FuncionarioUpload.module.css";
import { BASE_URL_API } from "../config.url";
import Menu from "../Menu";

const FuncionarioUpload = () => {
  const [file, setFile] = React.useState("");
  const fileRef = React.useRef(null);

  async function handleSubmit(event) {
    event.preventDefault();
    const dataForm = new FormData();
    dataForm.append("file", file);
    const res = fetch(`${BASE_URL_API}/funcionario/upload`, {
      method: "POST",
      body: dataForm,
    });
  }

  return (
    <>
      <Menu />
      <Container>
        <Row>
          <Col className="mt-4 text-center">
            <h1>Upload de Funcionário</h1>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form className={style.formulario} onSubmit={handleSubmit}>
              <Row className="g-2 mb-3">
                <Col md>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Control
                      ref={fileRef}
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                      disabled={global.modal}
                      accept=".csv"
                      type="file"
                      name="name"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Botao label={"Gravar"} desabilitado={!file} />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FuncionarioUpload;
