// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MovimentacaoEpi_forms__XNcJg{\n  position: relative;\n  margin-left: 25%;\n  margin-top: 6%;\n  width: 45%;\n  height: 550px;\n  background-color: #fff;\n  border-radius: 2rem;\n}\n\n.MovimentacaoEpi_titulo__FCu1w{\n  position: relative;\n  margin-left: 25%;\n  margin-top: 15%;\n}\n.MovimentacaoEpi_container__vtEbK {\n\tmax-width: 100%;\n\tmargin: 6% 3%;\n}\n.MovimentacaoEpi_tela__nr5ds{\n  max-width: 100%;\n  position: relative;\n}\n\n.MovimentacaoEpi_img__5xs6x{\n  margin-left: -25%;\n}\n\n.MovimentacaoEpi_imgPadrao__yua9Q{\n  margin-left: -15%;\n}\n", "",{"version":3,"sources":["webpack://./src/Estoque/MovimentacaoEpi.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;EACd,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;AACA;CACC,eAAe;CACf,aAAa;AACd;AACA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".forms{\n  position: relative;\n  margin-left: 25%;\n  margin-top: 6%;\n  width: 45%;\n  height: 550px;\n  background-color: #fff;\n  border-radius: 2rem;\n}\n\n.titulo{\n  position: relative;\n  margin-left: 25%;\n  margin-top: 15%;\n}\n.container {\n\tmax-width: 100%;\n\tmargin: 6% 3%;\n}\n.tela{\n  max-width: 100%;\n  position: relative;\n}\n\n.img{\n  margin-left: -25%;\n}\n\n.imgPadrao{\n  margin-left: -15%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"forms": "MovimentacaoEpi_forms__XNcJg",
	"titulo": "MovimentacaoEpi_titulo__FCu1w",
	"container": "MovimentacaoEpi_container__vtEbK",
	"tela": "MovimentacaoEpi_tela__nr5ds",
	"img": "MovimentacaoEpi_img__5xs6x",
	"imgPadrao": "MovimentacaoEpi_imgPadrao__yua9Q"
};
export default ___CSS_LOADER_EXPORT___;
