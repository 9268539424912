import React from "react";
import Menu from "../Menu";
import { Chart } from "react-google-charts";
import { BASE_URL_API } from "../config.url";
import { Container, Table } from "react-bootstrap";
import { Form, Col, Row, FloatingLabel } from "react-bootstrap";
import Botao from "../Botao/Botao";
const _ = require("lodash");
export const options = {
  chart: {
    title: "Custo em R$",
  },
};
const GraficoDashboard = () => {
  const [dataInicio, setdataInicio] = React.useState("");
  const [dataFim, setdataFim] = React.useState("");
  const [dataGrafico, setDataGrafico] = React.useState("");
  const [dataGraficoCopia, setDataGraficoCopia] = React.useState("");
  const [arrayGrafico, setArrayGrafico] = React.useState("");
  const [dt_mes_e_quantidade, setDt_mes_e_quantidade] = React.useState("");
  const [totalFuncionarios, setTotalFuncionarios] = React.useState("");
  const [totalGasto, setTotalGasto] = React.useState("");
  const [mes, setMes] = React.useState("");
  const [selectSetor, setSelectSetor] = React.useState("");
  const [arrayDeSetores, setArrayDeSetores] = React.useState("");
  const [fgPesquisaFuncionario, setFgPesquisaFuncionario] =
    React.useState(false);
  const [planilha, setPlanilha] = React.useState([]);
  const [tabela, setTabela] = React.useState("");
  const [episCampoSelect, setEpisCampoSelect] = React.useState([]);


  async function handleSubmit(event) {
    event.preventDefault();
    const response = await fetch(`${BASE_URL_API}/dashboard/grafico`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
        "x-acess-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        dt_inicio: dataInicio,
        dt_fim: dataFim,
      }),
    });
    const json = await response.json();
    const grafico = await json;
    var valor_total = 0;
    var funcionarios_total = 0;
    setDataGrafico(grafico);
    var dataTratada = ["mes", "custo"];
    var data = grafico.map((item) => {
      return Object.keys(item).map((objValue) => {
        return item[objValue];
      });
    });

    grafico.map((item) => {
      valor_total += Number(item.custo_total);
      setTotalFuncionarios(item.num_funcionarios);
    });
    setTotalGasto(valor_total);
    var data_mes_e_quantidade = data.map((item) => {
      return [item[0], parseFloat(item[5])];
    });
    setDt_mes_e_quantidade(data_mes_e_quantidade);
    setArrayGrafico([dataTratada, ...data_mes_e_quantidade]);
    setDataGraficoCopia([[dataTratada, ...data_mes_e_quantidade]]);
  }

  async function handleClick(item) {
    setSelectSetor(true);
    setMes(item);
    if (item !== "") {
      const response = await fetch(`${BASE_URL_API}/dashboard/grafico/setor`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
          "x-acess-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          mes: item,
        }),
      });
      const json = await response.json();
      var data = await json;
      const arrayMesSelecionado = dataGrafico.map((obj, i) => {
        if (obj["mes"] === item) {
          return dataGrafico[i];
        }
      });
      var valor_total = 0;
      var funcionarios_total = 0;
      arrayMesSelecionado.map((item) => {
        if (item !== undefined) {
          valor_total += item.custo_total;
          setTotalFuncionarios(data[0].num_funcionarios);
        }
      });
      setTotalGasto(valor_total);
      var data_mes_e_quantidade = data.map((item) => {
        return [item[0], parseFloat(item[5])];
      });

      data = _.sortBy(data, [
        (obj) => {
          return Number(obj.custo_total);
        },
      ]);
      var dataGraficoSetor = data.map((item) => {
        return Object.keys(item).map((objValue) => {
          return item[objValue];
        });
      });
      var custo_e_setor = dataGraficoSetor.map((item) => {
        return [item[1], parseFloat(item[4])];
      });
      setArrayDeSetores(custo_e_setor);
      setArrayGrafico([["setor", "valor"], ...custo_e_setor]);
      setDataGraficoCopia([
        ...dataGraficoCopia,
        [...[["setor", "valor"], ...custo_e_setor]],
      ]);
    }
  }

  function voltar(event) {
    event.preventDefault();

    if (dataGraficoCopia.length == 2 && fgPesquisaFuncionario) {
      setTabela("");
      setArrayGrafico(dataGraficoCopia[dataGraficoCopia.length - 1]);
      setFgPesquisaFuncionario(false);
    } else if (
      dataGraficoCopia.length === 2 &&
      fgPesquisaFuncionario === false
    ) {
      dataGraficoCopia.pop();
      setArrayDeSetores(false);
      setArrayGrafico(dataGraficoCopia[dataGraficoCopia.length - 1]);
    }
  }
  async function handleClickSetor(nm_departamento_contrato) {
    if (nm_departamento_contrato !== "") {
      const response = await fetch(
        `${BASE_URL_API}/dashboard/grafico/setor/epi`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
            "x-acess-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({
            mes: mes,
            nm_departamento_contrato: nm_departamento_contrato,
          }),
        }
      );
      const json = await response.json();
      const data = await json;
      var dataEpi = data.map((item) => {
        return Object.keys(item).map((objValue) => {
          return item[objValue];
        });
      });
      const epi = dataEpi.map((item) => {
        return [item[2], item[3]];
      });

      setEpisCampoSelect(epi);
    
      setFgPesquisaFuncionario(true);
      setPlanilha(dataEpi);
      setArrayGrafico([["epi", "quantidade"], ...epi]);
    }
  }

  function handleClickEpi(desc_epi) {
    if (desc_epi !== "") {
      const data = _.filter(planilha, (item) => {
        console.log(item[2]);
        return item[2] === desc_epi;
      });
      setTabela(data);
    }
  }
  return (
    <>
      <Menu />
      <Container>
        <Row>
          <Col className="text-center mt-3" sm="12">
            <h1>Dashboard </h1>
          </Col>

          <Col className="text-center mt-3" sm="12">
            {dataGrafico && (
              <h2>
                Custo por funcionário {mes ? `no mês de ${mes}` : ""}: R${" "}
                {Number(
                  Number(totalGasto).toFixed(2) / Number(totalFuncionarios)
                ).toFixed(2)}
                - Número de funcionários: {totalFuncionarios} - Total: R${" "}
                {Number(totalGasto).toFixed(2)}{" "}
              </h2>
            )}
          </Col>
        </Row>

        <Row>
          <Col sm>
            <Form className="p-5">
              <Row>
                <Col sm>
                  <FloatingLabel controlId="floatingInputGrid" label="Periodo">
                    <Form.Control
                      onChange={({ target }) => setdataInicio(target.value)}
                      type="date"
                      placeholder="2"
                    />
                  </FloatingLabel>
                </Col>
                <Col sm>
                  <FloatingLabel controlId="floatingInputGrid" label="Até">
                    <Form.Control
                      onChange={({ target }) => setdataFim(target.value)}
                      type="date"
                      placeholder="2"
                    />
                  </FloatingLabel>
                </Col>
                <Col sm>
                  <Botao click={handleSubmit} label="Pesquisar" />
                </Col>

                {dt_mes_e_quantidade && (
                  <Col sm>
                    <Botao click={voltar} label="Voltar" />
                  </Col>
                )}

                {dt_mes_e_quantidade && (
                  <Col sm>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="Selecionar mês"
                    >
                      <Form.Select
                        onChange={({ target }) => {
                          handleClick(target.value);
                        }}
                        placeholder="2"
                      >
                        <option value=""> </option>
                        {dt_mes_e_quantidade &&
                          dt_mes_e_quantidade.map((item, i) => {
                            return (
                              <>
                                <option value={`${item[0]}`}>
                                  {" "}
                                  {item[0]}{" "}
                                </option>
                              </>
                            );
                          })}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                )}

                {arrayDeSetores && selectSetor && (
                  <Col sm>
                    <FloatingLabel
                      controlId="floatingInputGridSetor"
                      label="Selecionar SETOR"
                    >
                      <Form.Select
                        onChange={({ target }) => {
                          setTabela(false);
                          setPlanilha("");
                          handleClickSetor(target.value);
                        }}
                        placeholder="1"
                      >
                        <option value=""> </option>
                        {arrayDeSetores.map((item) => {
                          return (
                            <>
                              <option value={`${item[0]}`}> {item[0]} </option>
                            </>
                          );
                        })}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                )}

                {fgPesquisaFuncionario && planilha && (
                  <Col sm>
                    <FloatingLabel
                      controlId="floatingInputGridEPI"
                      label="Seleciona EPI"
                    >
                      <Form.Select
                        onChange={({ target }) => {
                          handleClickEpi(target.value);
                        }}
                        placeholder="1"
                      >
                        <option value="" selected="selected">
                          {" "}
                          Selecione um EPI
                        </option>

                        {planilha.map((item) => {
                          return (
                            <>
                              <option value={`${item[2]}`}> {item[2]} </option>
                            </>
                          );
                        })}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                )}
              </Row>
            </Form>
          </Col>
        </Row>

        <Row>
          {dataGrafico && !tabela && (
            <Col className="text-center mt-3" sm="12">
              <Chart
                chartType="Bar"
                data={arrayGrafico}
                options={options}
                height="400px"
                chartEvents={[
                  {
                    eventName: "ready",
                    callback: ({ chartWrapper, google }) => {
                      const chart = chartWrapper.getChart();
                      google.visualization.events.addListener(
                        chart,
                        "onmouseover",
                        (e) => {
                          const { row, column } = e;
                        }
                      );
                    },
                  },
                ]}
              />
            </Col>
          )}
        </Row>

        <Row className="mt-3">
          {tabela && (
            <>
              <Col sm="12" className="text-center">
                <h2> Descrição do EPI: {tabela[0][2]}</h2>
                <h2> Frente de Trabalho: {tabela[0][1]}</h2>
              </Col>
              <Col sm="12">
                <Table striped bordered hover className="text-center mt-3">
                  <thead>
                    <tr>
                      <th>Funcionario</th>
                      <th>Dt.Entrega</th>
                      <th>CA</th>
                      <th>Valor Unitário</th>
                      <th>Quantidade</th>
                      <th>Valor Custo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabela.map((item, indice) => {
                      return (
                        <tr key={indice}>
                          <th>{item[5]}</th>
                          <th>{item[7]}</th>
                          <th>{item[6] === 0 ? "-" : item[6]}</th>
                          <th>R${item[4].replace(".", ",")}</th>
                          <th>{item[3]}</th>
                          <th>
                            R${" "}
                            {String(Number(item[4]) * item[3]).replace(
                              ".",
                              ","
                            )}
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </>
  );
};

export default GraficoDashboard;
