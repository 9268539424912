import React from "react";
import { Table } from "react-bootstrap";
import { BASE_URL_API } from "../config.url";
import Menu from "../Menu";

const RelatorioEntregaPorData = ({ dados }) => {
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Código EPI</th>
            <th>Descrição EPI</th>
            <th>Quantidade Entregue</th>
            <th>Frente de Serviço</th>
          </tr>
        </thead>
        <tbody>
          {dados &&
            dados.map((item, i) => {
              return (
                <tr key={i}>
                  <td key={item.cd_epi_emp}>{item.cd_epi_emp}</td>
                  <td key={item.desc_epi}>{item.desc_epi}</td>
                  <td key={item.quantidade}>{item.qt_entregue}</td>
                  <td key={item.nm_frente_de_servico_setor}>
                    {item.nm_frente_de_servico_setor}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
};

export default RelatorioEntregaPorData;
