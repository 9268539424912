// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Login_formulario__Ntqe8 {\n  background-color: black;\n  border-radius: 2rem;\n}\n\n.Login_logo__k5Ake {\n  border-radius: 50%;\n  border: white 5px solid;\n}\n\n.Login_fundo__uhwS1 {\n  position: absolute;\n  z-index: -1;\n}\n.Login_fundoImg__tuBQ8 {\n  width: 100vw;\n  height: 88vh;\n}\n", "",{"version":3,"sources":["webpack://./src/Login/Login.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,YAAY;EACZ,YAAY;AACd","sourcesContent":[".formulario {\n  background-color: black;\n  border-radius: 2rem;\n}\n\n.logo {\n  border-radius: 50%;\n  border: white 5px solid;\n}\n\n.fundo {\n  position: absolute;\n  z-index: -1;\n}\n.fundoImg {\n  width: 100vw;\n  height: 88vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formulario": "Login_formulario__Ntqe8",
	"logo": "Login_logo__k5Ake",
	"fundo": "Login_fundo__uhwS1",
	"fundoImg": "Login_fundoImg__tuBQ8"
};
export default ___CSS_LOADER_EXPORT___;
