import React from "react";
import Menu from "../Menu";
import { BASE_URL_API } from "../config.url";
import {
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import Botao from "../Botao/Botao";
import _ from "lodash";

const CustoTotalFuncionarios = () => {
  const [funcionarios, setFuncionarios] = React.useState("");
  const [dataInicio, setdataInicio] = React.useState("");
  const [dataFim, setdataFim] = React.useState("");
  const [nomeFuncionario, setNomeFuncionario] = React.useState("");
  const [epi, setEpi] = React.useState("");
  const [custoTotal, setCustoTotal] = React.useState("");

  function handleSubmit(event) {
    setEpi("");
    event.preventDefault();
    const body = !nomeFuncionario
      ? {
          dt_inicio: dataInicio,
          dt_fim: dataFim,
        }
      : {
          dt_inicio: dataInicio,
          dt_fim: dataFim,
          nm_funcionario: nomeFuncionario,
        };

    fetch(`${BASE_URL_API}/dashboard/funcionarios`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
        "x-acess-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((json) => {
        setFuncionarios(json);
      });
  }

  function handleClick(func) {
    const body = {
      dt_inicio: dataInicio,
      dt_fim: dataFim,
      num_matricula: func,
    };
    fetch(`${BASE_URL_API}/dashboard/funcionario/epi`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
        "x-acess-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((json) => {
        setEpi(json);
      });
  }

  if (funcionarios) {
    var total = 0;
    funcionarios.map((item) => {
      Object.keys(item).map((objeto, i) => {
        if (objeto === "total") {
          total += Number(item[Object.keys(item)[i]]);
        }
      });
    });
  }
  return (
    <>
      <Menu />
      <Container>
        <Row className="mt-4">
          <Col sm="12" className="text-center">
            <h1>Custo por funcionário</h1>
          </Col>
          <Col sm="12">
            <Form
              onSubmit={handleSubmit}
              className="d-flex justify-content-center mt-3"
            >
              <Row>
                <Col>
                  <FloatingLabel controlId="floatingInputGrid" label="Periodo">
                    <Form.Control
                      onChange={({ target }) => setdataInicio(target.value)}
                      type="date"
                      placeholder="2"
                    />
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel controlId="floatingInputGrid" label="Até">
                    <Form.Control
                      onChange={({ target }) => setdataFim(target.value)}
                      type="date"
                      placeholder="2"
                    />
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Nome Funcionário"
                  >
                    <Form.Control
                      onChange={({ target }) =>
                        setNomeFuncionario(target.value)
                      }
                      className
                      type="text"
                      placeholder="1"
                    />
                  </FloatingLabel>
                </Col>
                <Col>
                  <Botao label="Pesquisar" />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col sm="12">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Funcionário</th>
                  <th>Departamento</th>
                  <th>Setor</th>
                  <th>Função</th>
                  <th>Custo</th>
                </tr>
              </thead>
              <tbody>
                {funcionarios &&
                  funcionarios.map((item, indice) => {
                    return (
                      <tr
                        onClick={() => {
                          setFuncionarios([item]);
                          handleClick(item.num_matricula);
                        }}
                        key={indice}
                      >
                        <th>{item.nm_funcionario}</th>
                        <th>{item.nm_departamento_contrato}</th>
                        <th>{item.nm_frente_de_servico_setor}</th>
                        <th>{item.nm_funcao}</th>
                        <th>{item.total}</th>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Col>

          <Col sm="12">
            {epi && (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>EPI</th>
                    <th>Dt. entrega</th>
                    <th>CA</th>
                    <th>Vlr. unitario</th>
                    <th>Quantidade</th>
                    <th>Vlr. custo</th>
                  </tr>
                </thead>
                <tbody>
                  {epi &&
                    epi.map((item, indice) => {
                      return (
                        <tr key={indice}>
                          <th>{item.desc_epi}</th>
                          <th>
                            {item.dt_assinatura.split("-").reverse().join("/")}
                          </th>
                          <th>{item.num_ca}</th>
                          <th>{item.vl_unitario}</th>
                          <th>{item.qt_entregue}</th>
                          <th>{item.total_multiplicacao_epi_por_quantidade}</th>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            )}
          </Col>

          <Col sm="12">
            <p>
              <strong>
                Custo total: R$ {funcionarios && total.toFixed(2)}{" "}
              </strong>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CustoTotalFuncionarios;
