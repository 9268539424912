import React from "react";
import { Form, Row, Col, Container } from "react-bootstrap";
import Botao from "../Botao/Botao";
import style from "./EpiUpload.module.css";
import { BASE_URL_API } from "../config.url";
import Menu from "../Menu";

const EpiUpload = () => {
  const [file, setFile] = React.useState("");
  const fileRef = React.useRef(null);
  async function handleSubmit(event) {
    const dataForm = new FormData();
    dataForm.append("file", file);

    fetch(`${BASE_URL_API}/epi/upload`, {
      method: "POST",
      body: dataForm,
    })
      .then((response) => {
        if (response.ok) {
          alert("Upload realizado com sucesso");
        } else {
          return response.json();
        }
      })
      .then((json) => {
        if (json) {
          alert(json);
        }
        window.location.href = "/upload/epi";
      });
  }
  return (
    <>
      <Menu />
      <Container>
        <Row className="text-center mt-3">
          <Col>
            <h1>Upload de Epi</h1>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form className={style.formulario} onSubmit={handleSubmit}>
              <Row className="g-2 mb-3">
                <Col md>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Control
                      ref={fileRef}
                      required
                      style={{ marginTop: "4%" }}
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                      type="file"
                      name="file"
                      accept=".csv"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Botao label={"Gravar"} desabilitado={!file} />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EpiUpload;
