import React from 'react'
import Navbar from 'react-bootstrap/Navbar';
import Logo from './images/logo-horizontal.png';
const Header = () => {
  return (
    <Navbar bg="black" variant="dark" className="navbar">
      <Navbar.Brand href="/">
        <img
          alt=""
          src={Logo}
          width="300"
        />
      </Navbar.Brand>
  </Navbar>
  )
}



/*<header className="navbar navbar-header navbar-header-fixed" style={{backgroundColor: 'black', height: '70px'}}>
      	
      </header>*/


export default Header