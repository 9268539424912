import React from 'react'
import { EpiContext } from '../Hooks/EpiContext'
import style from './Botao.module.css'
const Botao = ({ label, desabilitado, click }) => {
  return (
    <>
      {
        desabilitado ?
          <button disabled className={style.button}>{label}</button> :
          <button onClick={click} className={style.button}>{label}</button>
      }
    </>
  )
}

export default Botao
