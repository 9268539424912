// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.SelecionaEpi_escolhaEpi__z8D6a{\n  position: absolute;\n  margin-left: 60%;\n  margin-top : -50% \n}\n\n@media (max-width: 1900px){\n  .SelecionaEpi_escolhaEpi__z8D6a{\n    position: absolute;\n    margin-left: 60%;\n    margin-top: -62%;\n  }\n}\n.SelecionaEpi_titulo__esk9P{\n  margin-left: 25%;\n  margin-top: 15%;\n}\n\n", "",{"version":3,"sources":["webpack://./src/Estoque/SelecionaEpi.module.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;EAClB;AACF;AACA;EACE,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":["\n.escolhaEpi{\n  position: absolute;\n  margin-left: 60%;\n  margin-top : -50% \n}\n\n@media (max-width: 1900px){\n  .escolhaEpi{\n    position: absolute;\n    margin-left: 60%;\n    margin-top: -62%;\n  }\n}\n.titulo{\n  margin-left: 25%;\n  margin-top: 15%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"escolhaEpi": "SelecionaEpi_escolhaEpi__z8D6a",
	"titulo": "SelecionaEpi_titulo__esk9P"
};
export default ___CSS_LOADER_EXPORT___;
