import React from "react";
import {BASE_URL_API} from '../config.url'

export const EpiContext  = React.createContext();

export const EpiGlobalStorage = ({children}) =>{
  const [dataSource, setDataSource] = React.useState([]);
  const [editingRow, setEditingRow] = React.useState(0);
  const [linha, setLinha] = React.useState(0);
  const [pesquisaFuncionario, setPesquisaFuncionario] = React.useState('');
  const [pesquisa, setPesquisa] = React.useState(false);
  const [dados, setDados] = React.useState("");
  const [localDoCorpo, setLocalDoCorpo] = React.useState("Cabeca");
  const [tipoEpi, setTipoEpi] = React.useState([]);
  const [descEpi, setDescEpi] = React.useState("");
  const [cdEpiSelecionado, setCdEpiSelecionado] = React.useState();
  const [keysEditadas, setKeyEditadas] = React.useState([]);
  const [ficha, setFicha] = React.useState("");
  const [modal, setModal] = React.useState(false);
  const [modalReconhecimento, setModalReconhecimento] = React.useState(false);
  const [log, setLog] = React.useState(false);
  const [qt_entregue, setQt_entregue] = React.useState(0);

  React.useMemo(() => {
    const data = [];
    for (let index = linha; index < linha+1; index++) {
      data.push({
        key: `${linha}`,
        desc_epi: `DESCRICAO EPI`,
        num_ca: 'Número do CA',    
        qt_entregue: `Unidade`,    
        dt_entregue: `Escolha uma data válida`,
      });
    }
    setLinha(linha+1);
    setDataSource(data);
  }, []);

  function resetTabela(){
    setLinha(0);
    const data = []
    for (let index = 0; index < linha+1; index++) {
      data.push({
        key: `${index}`,
        desc_epi: `Descricao EPI`,
        num_ca: 'Número do CA',    
        qt_entregue: `Unidade`,    
        dt_entregue: `DD/MM/YYYY`,
      });
    }
  }

  function addLinha(){
    const data = [...dataSource]
    for (let index = linha; index < linha+1; index++) {
      data.push({
        key: `${linha}`,
        desc_epi: `Descricao EPI`,
        num_ca: 'Número do CA',    
        qt_entregue: `Unidade`,    
        dt_entregue: `DD/MM/YYYY`,
      });
    }
    setLinha(linha+1);
    setDataSource(data);
  }
  
  

  return <EpiContext.Provider value={{qt_entregue, setQt_entregue, log, setLog,modal, setModal, modalReconhecimento, setModalReconhecimento, ficha, setFicha, resetTabela, linha, setLinha, keysEditadas, setKeyEditadas, descEpi, setDescEpi, cdEpiSelecionado, setCdEpiSelecionado, tipoEpi,localDoCorpo,setLocalDoCorpo,setTipoEpi,dataSource, editingRow,setDataSource, setEditingRow, addLinha,dados,pesquisa,pesquisaFuncionario,setDados,setPesquisa,setPesquisaFuncionario }}>{children}</EpiContext.Provider>;
}  
