import React from 'react'
import Header from './Header';
import Menu from './Menu';
import styles from './Home.module.css'

const Home = () => {
  return (
    <div>
      <Menu/>
      
    </div>
  )
}

export default Home