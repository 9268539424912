import React from "react";
import { EpiContext } from "./EpiContext";
import {Navigate} from 'react-router-dom' 

export const ProtectedRoute = ({children}) =>{
  
  return !localStorage.getItem("token") ? (  
    <>
      {alert("Sessão encerrada. Necessário se logar no sistema")} 
      <Navigate to="/login"></Navigate>   
    </>
    ) : children
}

