import React from "react";
import style from "./CadastroDeFuncionario.module.css";
import {
  Col,
  Container,
  FloatingLabel,
  Form,
  ListGroup,
  Row,
} from "react-bootstrap";
import Botao from "../Botao/Botao";
import { NavLink } from "react-router-dom";
import { BASE_URL_API } from "../config.url";
import Menu from "../Menu";
import { cpf } from "cpf-cnpj-validator";
import AWS from 'aws-sdk';

const S3_BUCKET = 'facial-recognition-teste';
const REGION = 'us-east-1';

AWS.config.update({
  accessKeyId: 'AKIAV7NEXYHGZU6FNRXQ',
  secretAccessKey: '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',
  region: REGION
});
const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const CadastroFuncionario = () => {
  const [verificaCpf, setVerificaCpf] = React.useState(true);

  const [funcionario, setFuncionario] = React.useState("");

  const [editar, setEditar] = React.useState(false);

  const [frenteSetor, setFrenteSetor] = React.useState("");

  const [contratoDepartamento, setContratoDepartamento] = React.useState("");

  const [funcao, setFuncao] = React.useState("");

  const [selecionaNome, setSelecionaNome] = React.useState(false);

  const [selecionaFuncao, setSelecionaFuncao] = React.useState(false);

  const [selecionaContratoDepartamento, setSelecionaContratoDepartamento] =
    React.useState(false);

  const [selecionaFrenteSetor, setSelecionaFrenteSetor] = React.useState(false);

  const [data, setData] = React.useState("");

  React.useEffect(() => {
    fetch(`${BASE_URL_API}/funcionarios/buscar/nome/${data.nm_funcionario}`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
        "x-acess-token": localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((json) => setFuncionario(json));
  }, [data.nm_funcionario]);

  React.useEffect(() => {
    fetch(
      `${BASE_URL_API}/funcionarios/buscar/frenteSetor/${data.nm_frente_de_servico_setor}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
          "x-acess-token": localStorage.getItem("token"),
        },
      }
    )
      .then((response) => response.json())
      .then((json) => setFrenteSetor(json));
  }, [data.nm_frente_de_servico_setor]);

  React.useEffect(() => {
    fetch(
      `${BASE_URL_API}/funcionarios/buscar/departamentoContrato/${data.nm_departamento_contrato}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
          "x-acess-token": localStorage.getItem("token"),
        },
      }
    )
      .then((response) => response.json())
      .then((json) => setContratoDepartamento(json));
  }, [data.nm_departamento_contrato]);

  React.useEffect(() => {
    fetch(`${BASE_URL_API}/funcionarios/buscar/funcao/${data.nm_funcao}`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
        "x-acess-token": localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((json) => setFuncao(json));
  }, [data.nm_funcao]);

  function mudarNome(obj) {
    setData(obj);
    setEditar(true);
    setSelecionaNome(false);
  }

  function mudarFuncao(obj) {
    data.nm_funcao = obj.nm_funcao;
    setData(data);
    setSelecionaFuncao(false);
  }

  function mudarContratoDepartamento(obj) {
    data.nm_departamento_contrato = obj.nm_departamento_contrato;
    setData(data);
    setSelecionaContratoDepartamento(false);
  }

  function mudarFrenteSetor(obj) {
    data.nm_frente_de_servico_setor = obj.nm_frente_de_servico_setor;
    setData(data);
    setSelecionaFrenteSetor(false);
  }

  function limparCampos() {
    setData("");
    setEditar("");
  }
  async function uploadImage(event){
    const promises = [];
    const files = event.target.files;
    Array.from(files).forEach(file => {
      if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
        var fileName = file.name.split(".");
        var extension = fileName.pop();
        fileName = data.num_matricula + "-validar." + extension;
        const params = {
          Bucket: S3_BUCKET,
          Key: fileName,
          ContentType: file.type,
          Body: file,
        };
        const promise = myBucket.upload(params).promise();
        promises.push(promise);
      } else {
        alert("Apenas arquivos de imagem são aceitos!");
      }
    });
  }
  function validaCPF() {
    if (data.num_cpf) {
      const regex = /([\D])/g;
      const num = data.num_cpf;
      var numLimpo = num.replace(regex, "");

      if (numLimpo.length === 11) {
        cpf.isValid(numLimpo) ? setVerificaCpf(true) : setVerificaCpf(false);
        if (setVerificaCpf) {
          setData({ ...data, num_cpf: cpf.format(numLimpo) });
        } else {
          setVerificaCpf(false);
          data.num_cpf = "";
          alert("CPF inválido");
        }
        numLimpo = "";
      } else {
        setVerificaCpf(false);
        data.num_cpf = "";
        alert("CPF inválido");
      }
    } else {
      data.num_cpf = "";
      setVerificaCpf(false);
    }
  }

  function handleSubmit(event) {
    data["tp_contrato"] = data.tp_contrato ? data.tp_contrato : "contratado";
    data["situacao_funcionario"] = data.situacao_funcionario
      ? data.situacao_funcionario
      : "1";
    validaCPF();
    const body = JSON.stringify(data);
    if (editar) {
      event.preventDefault();
      fetch(`${BASE_URL_API}/funcionario/editar/${data.num_matricula}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
          "x-acess-token": localStorage.getItem("token"),
        },
        body: body,
      })
        .then((response) => response.json)
        .then((json) => (window.location = "/funcionarios"));
    } else if (verificaCpf && data.num_cpf !== "") {
      fetch(`${BASE_URL_API}/funcionario/criar`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
          "x-acess-token": localStorage.getItem("token"),
        },
        body: body,
      })
        .then((response) => response.json)
        .then((json) => (window.location = "/funcionarios"));
    } else {
      event.preventDefault();
      alert("Campos inválidos");
    }
  }

  return (
    <>
      <Menu />
      <Container className="mt-5 text-center mb-3">
        <Row>
          <Col>
            <h1>CADASTRO DE FUNCIONÁRIO</h1>
          </Col>
        </Row>

        <Row className={style.formulario}>
          <Col sm={12}>
            <Form className="p-4 mt-4">
              <Row className="g-3 mb-3">
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Matrícula"
                  >
                    <Form.Control
                      required
                      value={data ? data.num_matricula : ""}
                      onChange={({ target }) =>
                        setData({ ...data, num_matricula: target.value })
                      }
                      type="text"
                      placeholder="1"
                      disabled={editar}
                    />
                  </FloatingLabel>
                </Col>
                <Col md>
                  <FloatingLabel controlId="floatingInputGrid" label="CPF">
                    <Form.Control
                      required
                      onChange={({ target }) => {
                        setData({ ...data, num_cpf: target.value });
                      }}
                      value={data ? data.num_cpf : ""}
                      onBlur={validaCPF}
                      type="text"
                      placeholder="2"
                      disabled={editar}
                    />
                  </FloatingLabel>
                  {data.num_cpf && !verificaCpf && !editar && (
                    <span style={{ color: "white", borderBox: "box-sizing" }}>
                      CPF INVÁLIDO
                    </span>
                  )}
                </Col>
              </Row>
              <Row className="g-2 mb-3">
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Nome do Funcionário"
                  >
                    <Form.Control
                      required
                      value={data ? data.nm_funcionario : ""}
                      onChange={({ target }) => {
                        setData({ ...data, nm_funcionario: target.value });
                        setSelecionaNome(true);
                      }}
                      type="text"
                      placeholder="2"
                    />
                  </FloatingLabel>
                  {funcionario &&
                    selecionaNome &&
                    funcionario.map((nome) => {
                      return (
                        <ListGroup className={style.nomes}>
                          <ListGroup.Item
                            className={style.nomes_items}
                            onClick={() => {
                              mudarNome(nome);
                            }}
                          >
                            {nome.nm_funcionario}
                          </ListGroup.Item>
                        </ListGroup>
                      );
                    })}
                  {/* {funcionario && (
                   <Form.Select>
                     {funcionario.map((obj) => {
                       return (
                         <option
                           key={obj.num_matricula}
                           value={obj.nm_funcionario}
                           onClick={() => {
                             mudarNome(obj);
                           }}
                         >
                           {obj.nm_funcionario}
                         </option>
                       );
                     })}
                   </Form.Select>
                 )} */}
                </Col>
              </Row>
              <Row className="g-2 mb-3">
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Frente de Serviço/Setor"
                  >
                    <Form.Control
                      required
                      value={data ? data.nm_frente_de_servico_setor : ""}
                      onChange={({ target }) => {
                        setData({
                          ...data,
                          nm_frente_de_servico_setor: target.value,
                        });
                        setSelecionaFrenteSetor(true);
                      }}
                      type="text"
                      placeholder="3"
                    />
                  </FloatingLabel>
                  {frenteSetor &&
                    selecionaFrenteSetor &&
                    !editar &&
                    frenteSetor.map((obj) => {
                      return (
                        <ListGroup className={style.nomes}>
                          <ListGroup.Item
                            className={style.nomes_items}
                            onClick={() => {
                              mudarFrenteSetor(obj);
                            }}
                          >
                            {obj.nm_frente_de_servico_setor}
                          </ListGroup.Item>
                        </ListGroup>
                      );
                    })}
                </Col>
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Data de admissão"
                  >
                    <Form.Control
                      required
                      value={data ? data.dt_admissao : ""}
                      onChange={({ target }) =>
                        setData({ ...data, dt_admissao: target.value })
                      }
                      type="date"
                      placeholder="2"
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="g-2 mb-3">
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Contrato/Departamento"
                  >
                    <Form.Control
                      required
                      value={data ? data.nm_departamento_contrato : ""}
                      onChange={({ target }) => {
                        setData({
                          ...data,
                          nm_departamento_contrato: target.value,
                        });
                        setSelecionaContratoDepartamento(true);
                      }}
                      type="text"
                      placeholder="2"
                    />
                  </FloatingLabel>
                  {contratoDepartamento &&
                    selecionaContratoDepartamento &&
                    !editar &&
                    contratoDepartamento.map((obj) => {
                      return (
                        <ListGroup className={style.nomes}>
                          <ListGroup.Item
                            className={style.nomes_items}
                            onClick={() => {
                              mudarContratoDepartamento(obj);
                            }}
                          >
                            {obj.nm_departamento_contrato}
                          </ListGroup.Item>
                        </ListGroup>
                      );
                    })}
                </Col>
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Situação do Funcionário"
                  >
                    <Form.Select
                      value={data ? data.situacao_funcionario : ""}
                      onChange={({ target }) =>
                        setData({ ...data, situacao_funcionario: target.value })
                      }
                      placeholder="2"
                    >
                      <option value="1">Ativo</option>
                      <option value="0">Inativo</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="g-2 mb-3">
                <Col md>
                  <FloatingLabel controlId="floatingInputGrid" label="Função">
                    <Form.Control
                      value={data ? data.nm_funcao : ""}
                      required
                      onChange={({ target }) => {
                        setData({ ...data, nm_funcao: target.value });
                        setSelecionaFuncao(true);
                      }}
                      type="text"
                      placeholder="2"
                    />
                  </FloatingLabel>
                  {funcao &&
                    selecionaFuncao &&
                    !editar &&
                    funcao.map((obj) => {
                      return (
                        <ListGroup className={style.nomes}>
                          <ListGroup.Item
                            className={style.nomes_items}
                            onClick={() => {
                              mudarFuncao(obj);
                            }}
                          >
                            {obj.nm_funcao}
                          </ListGroup.Item>
                        </ListGroup>
                      );
                    })}
                </Col>
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Contrato do Funcionário"
                  >
                    <Form.Select
                      onChange={({ target }) =>
                        setData({ ...data, tp_contrato: target.value })
                      }
                      placeholder="2"
                    >
                      <option value="contratado">Contratado</option>
                      <option value="terceiro">Terceiro</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col md>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label className="text-white">
                      Imagem do Funcionário
                    </Form.Label>
                    <Form.Control
                      onChange={uploadImage}
                      type="file"
                      accept="image/jpg"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Col>

          <Col className="mb-5">
            <Botao
              label={editar ? "Salvar" : "Cadastrar"}
              click={handleSubmit}
            />
          </Col>
          <Col>
            <NavLink to="/upload/funcionario">
              <Botao label={"Upload"}> </Botao>
            </NavLink>
          </Col>
          {editar && (
            <Col>
              <Botao label={"Limpar"} click={limparCampos}></Botao>
            </Col>
          )}
        </Row>
      </Container>
      
    </>
  );
};

export default CadastroFuncionario;
