import React from "react";

import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import Botao from "../Botao/Botao";
import style from "../Estoque/VerificaUsuario.module.css";

import { BASE_URL_API } from "../config.url";
import RelatorioEntregaPorData from "../TabelasRelatorios/RelatorioEntregaPorData";
import Menu from "../Menu";

const EntregaEpi = () => {
  const [pesquisaFuncionario, setPesquisaFuncionario] = React.useState("");
  const [pesquisa, setPesquisa] = React.useState(false);
  const [dados, setDados] = React.useState("");
  const [dataInicio, setDataInicio] = React.useState("");
  const [dataFim, setDataFim] = React.useState("");

  function handleSubmit(event) {
    event.preventDefault();
    var body;
    if (dataFim) {
      body = {
        dt_assinatura: dataInicio,
        dt_assinatura_fim: dataFim,
      };
    } else {
      body = {
        dt_assinatura: dataInicio,
      };
    }
    fetch(`${BASE_URL_API}/ficha/epi/funcionario/dia/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
        "x-acess-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((json) => {
        setDados(json);
      });
    setPesquisa(true);
  }

  return (
    <>
      <Menu />
      <Container>
        <Row>
          <Col md>
            <h1 className="mt-4 text-center">CONSULTA DE ENTREGA DE EPI</h1>
          </Col>
        </Row>

        <Row>
          <Col md>
            <Form className={style.formulario} onSubmit={handleSubmit}>
              <Row>
                <Col md>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      Data Início
                    </InputGroup.Text>
                    <Form.Control
                      required
                      size="sm"
                      onChange={({ target }) => {
                        setDataInicio(target.value);
                      }}
                      type="date"
                    />
                  </InputGroup>
                </Col>
              </Row>

              <Row>
                <Col md>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      Data Fim
                    </InputGroup.Text>
                    <Form.Control
                      size="sm"
                      onChange={({ target }) => {
                        setDataFim(target.value);
                      }}
                      type="date"
                    />
                  </InputGroup>
                </Col>
              </Row>

              <Row>
                <Col md>
                  <Botao label={"Pesquisar"} />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        {pesquisa && dados && (
          <Row>
            <Col md className="mt-5">
              <RelatorioEntregaPorData dados={dados} />
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default EntregaEpi;
