import React from "react";
import style from "./Login.module.css";
import { Col, Container, Form, InputGroup, Navbar, Row } from "react-bootstrap";
import Botao from "../Botao/Botao";
import { BASE_URL_API } from "../config.url";
import { Route, useNavigate } from "react-router-dom";
import { EpiContext } from "../Hooks/EpiContext";
import LogoNavBar from "../images/logo-horizontal.png";
import LogoLogin from "../images/LogoRigbie150x150.png";
import fundo from "../images/fundo.png";

const Login = () => {
  const [login, setLogin] = React.useState("");
  const [password, setPassword] = React.useState("");

  function validateForm() {
    return login.length > 0 && password.length > 0;
  }
  const global = React.useContext(EpiContext);
  const navigate = useNavigate();
  async function handleSubmit(event) {
    event.preventDefault();
    const loginUsuario = {
      nm_usuario: login,
      senha_usuario: password,
    };
    const res = await fetch(`${BASE_URL_API}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
        "x-acess-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(loginUsuario),
    });
    const json = await res.json();
    const jsonToken = await json;
    if (jsonToken.token) {
      localStorage.setItem("token", jsonToken.token);
      navigate("/movimentacao/epi/funcionario");
    } else {
      alert(jsonToken);
    }
  }
  return (
    <>
      <header>
        <Navbar bg="dark" className="p-2">
          <Container className="m-0">
            <Navbar.Brand href="/" className="w-25">
              <img src={LogoNavBar} className="img-fluid" alt="Rigbie logo." />
            </Navbar.Brand>
          </Container>
        </Navbar>
      </header>
      <main>
        <div className={style.fundo}>
          <img src={fundo} className={style.fundoImg} alt="Imagem de fundo." />
        </div>
        <Container>
          <Row>
            <Col>
              <Form
                className={`${style.formulario} mt-5 w-50 p-4 float-end`}
                onSubmit={handleSubmit}
              >
                <Row>
                  <Col md className="text-center mb-4">
                    <img
                      src={LogoLogin}
                      alt="Logo da Rigbie."
                      className={`${style.logo} img-fluid w-25`}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12}>
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="email-addon"
                        style={{
                          backgroundColor: "rgb(234, 146, 0)",
                          border: "none",
                          color: "white",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          fill="currentColor"
                          className="bi bi-envelope-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                        </svg>
                      </InputGroup.Text>
                      <Form.Control
                        size="sm"
                        onChange={({ target }) => {
                          setLogin(target.value);
                        }}
                        type="text"
                      />
                    </InputGroup>
                  </Col>

                  <Col sm={12}>
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="pass-addon"
                        style={{
                          backgroundColor: "rgb(234, 146, 0)",
                          border: "none",
                          color: "white",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          fill="currentColor"
                          className="bi bi-key-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2zM2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                        </svg>
                      </InputGroup.Text>
                      <Form.Control
                        size="sm"
                        onChange={({ target }) => {
                          setPassword(target.value);
                        }}
                        type="password"
                      />
                    </InputGroup>
                  </Col>

                  <Col sm={12}>
                    <div className="d-grid gap-2">
                      <Botao label={"Logar"} />
                    </div>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
};

export default Login;
