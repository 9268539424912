// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CadastroDeEpi_formulario__wGVBc {\n  background-color: rgb(34, 34, 34);\n  border-radius: 1rem;\n  padding: 2%;\n}\n", "",{"version":3,"sources":["webpack://./src/Cadastro/CadastroDeEpi.module.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,mBAAmB;EACnB,WAAW;AACb","sourcesContent":[".formulario {\n  background-color: rgb(34, 34, 34);\n  border-radius: 1rem;\n  padding: 2%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formulario": "CadastroDeEpi_formulario__wGVBc"
};
export default ___CSS_LOADER_EXPORT___;
