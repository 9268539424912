import React from "react";
import style from "./MovimentacaoEpi.module.css";
import VerificaUsuario from "./VerificaUsuario";
import { Col, Container, Row } from "react-bootstrap";
import Tabela from "../TabelaEpi/Tabela";
import { EpiContext } from "../Hooks/EpiContext";
import Menu from "../Menu";
const MovimentacaoDeEpi = () => {
  const global = React.useContext(EpiContext);
  return (
    <>
      <Menu />
      <VerificaUsuario />
      {global.pesquisa && (
        <>
          <Container>
            <Row>
              <Col>
                <h1 className="text-center text-uppercase mt-5">
                  ENTREGA DE EPI
                </h1>
              </Col>
            </Row>
            <Row>
              <Col className="mt-4">
                <Tabela />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default MovimentacaoDeEpi;
