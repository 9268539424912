// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MovimentacaoEstoque_formulario__wqdG3 {\n  padding: 3%;\n  border-radius: 1rem;\n  background-color: rgb(34, 34, 34);\n}\n", "",{"version":3,"sources":["webpack://./src/Estoque/MovimentacaoEstoque.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;EACnB,iCAAiC;AACnC","sourcesContent":[".formulario {\n  padding: 3%;\n  border-radius: 1rem;\n  background-color: rgb(34, 34, 34);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formulario": "MovimentacaoEstoque_formulario__wqdG3"
};
export default ___CSS_LOADER_EXPORT___;
