// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EpiUpload_formulario__6-Y7M {\n  border-radius: 1rem;\n  background-color: var(--bs-body-color);\n  padding: 2%;\n}", "",{"version":3,"sources":["webpack://./src/Cadastro/EpiUpload.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,sCAAsC;EACtC,WAAW;AACb","sourcesContent":[".formulario {\n  border-radius: 1rem;\n  background-color: var(--bs-body-color);\n  padding: 2%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formulario": "EpiUpload_formulario__6-Y7M"
};
export default ___CSS_LOADER_EXPORT___;
