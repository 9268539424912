import React from "react";

import {
  Col,
  Container,
  FloatingLabel,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import Botao from "../Botao/Botao";
import style from "../Estoque/VerificaUsuario.module.css";

import { BASE_URL_API } from "../config.url";
import Menu from "../Menu";
import { EpiContext } from "../Hooks/EpiContext";

const FichaEpiLegal = () => {
  const global = React.useContext(EpiContext);
  const [funcionario, setFuncionario] = React.useState("");
  async function handleSubmit(event) {
    event.preventDefault();
    const matriculaFuncionario = {
      num_matricula: global.pesquisaFuncionario,
    };
    window.open(
      `${BASE_URL_API}/ficha/epi/emissao/pdf/${matriculaFuncionario.num_matricula}`
    );
  }
  React.useMemo(() => {
    fetch(`${BASE_URL_API}/funcionarios`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
        "x-acess-token": localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((json) => setFuncionario(json));
  }, []);

  return (
    <>
      <Menu />
      <Container>
        <Row>
          <Col md>
            <h1 className="mb-5 text-center mt-4">PESQUISA DE FUNCIONÁRIO</h1>
          </Col>
        </Row>

        <Row>
          <Col sm>
            <Form className={style.formulario} onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Funcionários"
                  >
                    <Form.Select
                      onChange={({ target }) =>
                        global.setPesquisaFuncionario(target.value)
                      }
                      placeholder="2"
                    >
                      <option></option>
                      {funcionario &&
                        funcionario.map((objeto) => {
                          return (
                            <option
                              key={objeto.num_matricula}
                              value={objeto.num_matricula}
                            >
                              {objeto.nm_funcionario} matrícula:{" "}
                              {objeto.num_matricula}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col md className="mt-4">
                  <Botao label={"Pesquisar"} />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FichaEpiLegal;
