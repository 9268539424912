import React from "react";
import { Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import { EpiContext } from "../Hooks/EpiContext";
import style from "./SelecionaEpi.module.css";
import { BASE_URL_API } from "../config.url";
import Menu from "../Menu";

const SelecionaEpi = () => {
  const [localDoCorpo, setLocalDoCorpo] = React.useState("Cabeça");
  const [tipoEpi, setTipoEpi] = React.useState("");
  const [dt_entregue, setDt_entregue] = React.useState("");
  const [flagData, setFlagData] = React.useState(false);
  const [key, setKey] = React.useState(1);
  const global = React.useContext(EpiContext);

  const dia =
    new Date().getDate() > 9
      ? new Date().getDate()
      : "0" + new Date().getDate();
  const mes =
    new Date().getMonth() > 9
      ? new Date().getMonth()
      : "0" + (new Date().getMonth() + 1);
  const ano = new Date().getFullYear();
  const dataHoje = ano + "-" + mes + "-" + dia;

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      const updatedDataSource = [...global.dataSource];
      const data = {
        key: key,
        desc_epi: global.descEpi,
        cd_epi: Number(global.cdEpiSelecionado),
        cd_ficha: Number(global.dados.id_ficha),
        qt_entregue: Number(global.qt_entregue),
        dt_assinatura: dt_entregue ? dt_entregue : dataHoje,
      };
      updatedDataSource.push(data);
      global.setDataSource(updatedDataSource);
      global.setDescEpi("");
      global.setCdEpiSelecionado(undefined);
      setKey(key + 1);
      setFlagData(false);
    }
  };
  React.useMemo(() => {
    fetch(`${BASE_URL_API}/epi/partecorpo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
        "x-acess-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ nm_parte_corpo: localDoCorpo }),
    })
      .then((response) => response.json())
      .then((json) => setTipoEpi(json));
      console.log(tipoEpi);
  }, [localDoCorpo]);

  function atualizaValores({ target }) {
    const selecionaEpi = tipoEpi.find((item) => {
      if (Number(item.id) === Number(target.value)) {
        return {
          id: item.id,
          desc_epi: item.desc_epi,
          qt_estoque: item.qt_estoque,
        };
      }
    });
    if (selecionaEpi.qt_estoque <= 0) {
      alert(
        "Quantidade de estoque deste epi menor ou igual a zero, por favor revisar quantidade de estoque deste EPI"
      );
      target.value = "";
    } else {
      global.setDescEpi(selecionaEpi.desc_epi);
      global.setCdEpiSelecionado(selecionaEpi.id);
    }
  }

  return (
    <>
      <Container>
        <Row>
          <Col sm={12} className="text-center mb-2">
            <h1>Local do Corpo</h1>
          </Col>

          <Col sm="12">
            <FloatingLabel controlId="floatingInputGrid" label="Parte do Corpo">
              <Form.Select
                onChange={({ target }) => setLocalDoCorpo(target.value)}
                placeholder="2"
              >
                <option value="Cabeça">Cabeça</option>
                <option value="Membros Superiores (Mão, Braço)">
                  Membros Superiores
                </option>
                <option value="Tronco"> Tronco</option>
                <option value="Membros Inferiores (Pé, Pernas)">
                  Membros Inferiores
                </option>
              </Form.Select>
            </FloatingLabel>
          </Col>
        </Row>
        <Row className=" mt-3">
          <Col md={12} className="text-center">
            <h1>Tipo de EPI</h1>
          </Col>
          <Col md={12} className="mt-2">
            <FloatingLabel controlId="floatingInputGrid" label="EPI">
              <Form.Select onChange={atualizaValores} placeholder="2">
                <option></option>
                {tipoEpi &&
                  tipoEpi.map((objeto) => {
                    return (
                      <option key={objeto.id} value={objeto.id}>
                        {`id:${objeto.cd_epi_emp} descrição: ${objeto.desc_epi} estoque: ${objeto.qt_estoque}`}
                      </option>
                    );
                  })}
              </Form.Select>
            </FloatingLabel>
          </Col>
          <Col className="mt-3">
            {global.cdEpiSelecionado !== undefined ? (
              <FloatingLabel controlId="floatingInputGrid" label="Qntd.">
                <Form.Control
                  onChange={({ target }) => {
                    global.setQt_entregue(target.value);
                    setFlagData(true);
                  }}
                  autoFocus
                ></Form.Control>
              </FloatingLabel>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row>
          <Col className="mt-3">
            {flagData ? (
              <FloatingLabel controlId="Data" label="Data de entrega">
                <Form.Control
                  required
                  value={dt_entregue ? dt_entregue : dataHoje}
                  autoFocus
                  onChange={({ target }) => setDt_entregue(target.value)}
                  onKeyPress={handleKeyPress}
                  type="date"
                  placeholder="2"
                />
              </FloatingLabel>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SelecionaEpi;
