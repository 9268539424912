import React from "react";
import style from "./CadastroDeEpi.module.css";
import { ReactComponent as Epi } from "../images/5.svg";
import { Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import Botao from "../Botao/Botao";
import { NavLink } from "react-router-dom";
import { BASE_URL_API } from "../config.url";
import Menu from "../Menu";

const CadastroDeEpi = () => {
  const [cd_epi_emp, setCd_epi_emp] = React.useState("");
  const [desc_epi, setDesc_epi] = React.useState("");
  const [num_ca, setNum_ca] = React.useState("");
  const [nm_parte_corpo, setNm_parte_corpo] = React.useState("");
  const [qt_estoque, setQt_estoque] = React.useState("");
  const [vl_unitario, setVl_unitario] = React.useState("");

  const body = {
    cd_epi_emp: Number(cd_epi_emp),
    desc_epi: desc_epi,
    num_ca: Number(num_ca),
    nm_parte_corpo: nm_parte_corpo,
    qt_estoque: Number(qt_estoque),
    vl_unitario: parseFloat(vl_unitario),
  };
  function handleSubmit(event) {
    fetch(`${BASE_URL_API}/epi/criar`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
        "x-acess-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(body),
    });
  }
  return (
    <>
      <Menu />
      <Container>
        <Row>
          <Col className="text-center mt-4">
            <h1>CADASTRO DE EPI</h1>
          </Col>
        </Row>

        <Row className={style.formulario}>
          <Col sm={12}>
            <Form>
              <Row className="g-3 mb-3">
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Código Empr. EPI"
                  >
                    <Form.Control
                      required
                      onChange={({ target }) => setCd_epi_emp(target.value)}
                      style={{ marginTop: "5%" }}
                      type="text"
                      placeholder="1"
                    />
                  </FloatingLabel>
                </Col>
                <Col md>
                  <FloatingLabel controlId="floatingInputGrid" label="CA">
                    <Form.Control
                      required
                      onChange={({ target }) => setNum_ca(target.value)}
                      style={{ marginTop: "5%" }}
                      type="text"
                      placeholder="2"
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="g-2 mb-3">
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Descrição do EPI (contendo o tamanho)"
                  >
                    <Form.Control
                      required
                      onChange={({ target }) => setDesc_epi(target.value)}
                      type="text"
                      placeholder="2"
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="g-2 mb-3">
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Parte do corpo"
                  >
                    <Form.Control
                      required
                      onChange={({ target }) => setNm_parte_corpo(target.value)}
                      type="text"
                      placeholder="2"
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="g-3 mb-3">
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Valor Unitário"
                  >
                    <Form.Control
                      required
                      onChange={({ target }) => setVl_unitario(target.value)}
                      type="text"
                      placeholder="2"
                    />
                  </FloatingLabel>
                </Col>
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Quantidade"
                  >
                    <Form.Control
                      required
                      onChange={({ target }) => setQt_estoque(target.value)}
                      type="text"
                      placeholder="2"
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </Form>
          </Col>

          <Col sm={6}>
            <Botao label={"Cadastrar"} click={handleSubmit} />
          </Col>
          <Col sm={6}>
            <div className={style.upload}>
              <NavLink to="/upload/epi" end>
                <Botao label={"Upload"}> </Botao>
              </NavLink>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CadastroDeEpi;
