import React, { useRef, useState, useEffect } from "react";
import Webcam from "react-webcam";
import { BASE_URL_API } from "../config.url";
import ModalPadrao from "./ModalPadrao";
// import axios from 'axios';
import AWS from "aws-sdk";
import { EpiContext } from "../Hooks/EpiContext";
import { Button, Col, Row } from "react-bootstrap";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "../Modais/ModalPadrao.module.css";

const S3_BUCKET = "facial-recognition-teste";
const REGION = "us-east-1";

AWS.config.update({
  accessKeyId: "AKIAV7NEXYHG6DIQMKZB",
  secretAccessKey: "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",
  region: REGION,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const WebcamCapture = ({ nome, matricula }) => {
  const global = React.useContext(EpiContext);
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [fileName, setFileName] = useState(`${matricula}-validar.jpg`);
  const [tentativas, setTentativas] = useState(0);
  const [crop, setCrop] = useState({
    x: 170,
    y: 5,
    width: 300,
    height: 470,
    unit: 'px',
  });

  const capture = React.useCallback(async () => {
    if (!webcamRef.current) {
      return;
    }

    const image = cropImage();
    const file = dataURLtoFile(image, `${global.dados.num_matricula}-validar.jpg`);

    await myBucket.putObject(
      {
        Key: fileName,
        Body: file,
        ContentType: "image/png",
      },
      (error) => {
        if (error) {
          console.log(error);
          alert("Error uploading image");
          return;
        }
      }
    );
    //wait 100 millisconds to execute next line
    await new Promise((r) => setTimeout(r, 300));
    //call lambda function

    fetch(
      "https://osajiomgp2.execute-api.us-east-1.amazonaws.com/default/faceAnalise/recognition",
      {
        method: "POST",
        body: JSON.stringify({
          image_name: fileName,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 200) {
          alert("Pessoa encontrada");
          if (global.dataSource.length >= 1) {
            global.dataSource.map((item) => {
              delete item.key;
            });
            global.dataSource.shift();
            const body = JSON.stringify(global.dataSource);
            fetch(`${BASE_URL_API}/ficha/epi/inserir`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
                "x-acess-token": localStorage.getItem("token"),
              },
              body: body,
            })
              .then((response) => response.json())
              .then((json) => {
                alert(json);
                window.location.href = "/movimentacao/epi/funcionario";
              });
          }
        } else {
          setTentativas((tentativas) => tentativas + 1);
          novamente();
        }
      })
      .catch((error) => console.log(error));
  }, [webcamRef]);

  async function captureForRecognition() {
    if (!webcamRef.current) {
      return;
    }

    const image = cropImage();
    const file = dataURLtoFile(image, `${global.dados.num_matricula}-validar.jpg`);

    const promises = [];
    const params = {
      Bucket: S3_BUCKET,
      Key: file.name,
      ContentType: file.type,
      Body: file,
    };

    const promise = myBucket.upload(params).promise();
    promises.push(promise);
  }

  function cropImage() {
    const video = webcamRef.current.video;
    const canvas = document.createElement('canvas');
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(video, crop.x, crop.y, crop.width, crop.height, 0, 0, crop.width, crop.height);

    const image = canvas.toDataURL('image/jpeg', 0.8);

    return image;
  }

  const dataURLtoFile = (dataURL, fileName) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  };

  const resetCapture = () => {
    setCapturedImage(null);
  };

  function novamente() {
    let resposta = window.confirm("Pessoa não encontrada! Deseja tentar novamente?");
    resetCapture();
    if (resposta) {
      capture();
    } else {
      global.setModal(false);
      global.setModalReconhecimento(false);
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      capture();
    }, 3000); // Automatically take a picture after 3 seconds

    return () => clearTimeout(timer);
  }, [capture]);

  return (
    <>
      <Row>
        <Col sm={12}>
          <ReactCrop crop={crop} disabled={true} circularCrop={true}>
            <Webcam
              audio={false}
              ref={webcamRef}
            />
          </ReactCrop>
        </Col>

        {tentativas >= 3 && (
          <>
            <hr/>

            <Col sm={12}>
              <Button variant="secondary" onClick={captureForRecognition}>
                Tirar foto para reconhecimento
              </Button>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

function ModalReconhecimento({ nome, matricula }) {
  return (
    <ModalPadrao
      texto={<WebcamCapture nome={nome} matricula={matricula}/>}
      titulo="Reconhecimento Facial" reconhecimento={true}
    />
  );
}

export default ModalReconhecimento;
