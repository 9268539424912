import React from "react";

import {
  Form,
  InputGroup,
  FloatingLabel,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import Botao from "../Botao/Botao";
import { EpiContext } from "../Hooks/EpiContext";
import style from "./VerificaUsuario.module.css";
import { BASE_URL_API } from "../config.url";
import Menu from "../Menu";
import { useNavigate } from "react-router-dom";
import SelecionaEpi from "./SelecionaEpi";

const VerificaUsuario = () => {
  const navigate = useNavigate();
  const global = React.useContext(EpiContext);
  const [funcionario, setFuncionario] = React.useState("");

  React.useMemo(() => {
    fetch(`${BASE_URL_API}/funcionarios`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
        "x-acess-token": localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((json) => setFuncionario(json));
  }, []);
  function handleSubmit(event) {
    event.preventDefault();

    fetch(`${BASE_URL_API}/funcionarios/${global.pesquisaFuncionario}`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
        "x-acess-token": localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((json) => global.setDados(json));
    global.setPesquisa(true);
  }

  return (
    <>
      <Container>
        <Row>
          <Col className="mt-4 text-center">
            <h1>PESQUISA DE FUNCIONÁRIO</h1>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3" md={6}>
            <Form className={style.formulario} onSubmit={handleSubmit}>
              {!global.pesquisa && (
                <>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Funcionários"
                  >
                    <Form.Select
                      className="mb-3"
                      onChange={({ target }) =>
                        global.setPesquisaFuncionario(target.value)
                      }
                      placeholder="2"
                    >
                      <option></option>
                      {funcionario &&
                        funcionario.map((objeto) => {
                          return (
                            <option
                              key={objeto.num_matricula}
                              value={objeto.num_matricula}
                            >
                              {objeto.nm_funcionario} matrícula:{" "}
                              {objeto.num_matricula}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </FloatingLabel>
                  <Botao label={"Pesquisar"} />
                </>
              )}
              {global.pesquisa && (
                <>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      Nome Funcionário
                    </InputGroup.Text>
                    <Form.Control
                      size="sm"
                      disabled={true}
                      value={global.dados ? global.dados.nm_funcionario : ""}
                      type="text"
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      Matrícula do Funcionário
                    </InputGroup.Text>
                    <Form.Control
                      size="sm"
                      disabled={true}
                      value={global.dados ? global.dados.num_matricula : ""}
                      type="text"
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Função</InputGroup.Text>
                    <Form.Control
                      size="sm"
                      disabled={true}
                      value={global.dados ? global.dados.nm_funcao : ""}
                      type="text"
                    />
                  </InputGroup>
                  <Botao
                    label={"Pesquisa"}
                    desabilitado={false}
                    click={() =>
                      (window.location.href = "/movimentacao/epi/funcionario")
                    }
                  />
                </>
              )}
            </Form>
          </Col>

          {global.pesquisa && (
            <Col md>
              <SelecionaEpi />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default VerificaUsuario;
