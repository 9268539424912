import React from "react";
import Header from "./Header";
import Home from "./Home";
import Menu from "./Menu";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CadastroFuncionario from "./Cadastro/CadastroFuncionario.js";
import CadastroDeEpi from "./Cadastro/CadastroDeEpi.js";
import CadastroDeRisco from "./Cadastro/CadastroDeRisco.js";
import MovimentacaoDeEpi from "./Estoque/MovimentacaoDeEpi.js";
import MovimentacaoDeEstoque from "./Estoque/MovimentacaoDeEstoque.js";
import EntregaEpi from "./Relatorios/EntregaEpi.js";
import VerificaUsuario from "./Estoque/VerificaUsuario";
import FuncionarioUpload from "./Cadastro/FuncionarioUpload";
import { EpiGlobalStorage } from "./Hooks/EpiContext";
import EpiUpload from "./Cadastro/EpiUpload";
import FichaEpiLegal from "./Relatorios/FichaEpiLegal";
import Login from "./Login/Login";
import { ProtectedRoute } from "./Hooks/ProtectedRoute";
import CustoTotalFuncionarios from "./Dashboards/CustoTotalFuncionarios";
import PaginaInicialDashboards from "./Dashboards/PaginaInicialDashboards";
import PosicaoDeEstoque from "./Dashboards/PosicaoDeEstoque";
import GraficoDashboard from "./Dashboards/GraficoDashboard";
import { Container, Navbar } from "react-bootstrap";
import Logo from "./images/logo-horizontal.png";
import CadastroImagem from "./Cadastro/CadastroDeImagens";

const App = () => {
  return (
    <div className="content content-fixed content-auth">
      <EpiGlobalStorage>
        <BrowserRouter>
          {/* <Header /> */}

          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/funcionarios"
              element={
                <ProtectedRoute>
                  <CadastroFuncionario />
                </ProtectedRoute>
              }
            />
            <Route
              path="/epi"
              element={
                <ProtectedRoute>
                  <CadastroDeEpi />
                </ProtectedRoute>
              }
            />
            <Route
              path="/risco"
              element={
                <ProtectedRoute>
                  <CadastroDeRisco />
                </ProtectedRoute>
              }
            />
            <Route
              path="/imagens"
              element={
                <ProtectedRoute>
                  <CadastroImagem />
                </ProtectedRoute>
              }
            />
            <Route
              path="/estoque"
              element={
                <ProtectedRoute>
                  <MovimentacaoDeEstoque />
                </ProtectedRoute>
              }
            />
            <Route
              path="/estoque/epi"
              element={
                <ProtectedRoute>
                  <VerificaUsuario />
                </ProtectedRoute>
              }
            />
            <Route
              path="/movimentacao/epi/funcionario"
              element={
                <ProtectedRoute>
                  <MovimentacaoDeEpi />
                </ProtectedRoute>
              }
            />
            <Route
              path="/entrega"
              element={
                <ProtectedRoute>
                  <EntregaEpi />
                </ProtectedRoute>
              }
            />
            <Route
              path="/upload/funcionario"
              element={
                <ProtectedRoute>
                  <FuncionarioUpload />
                </ProtectedRoute>
              }
            />
            <Route
              path="/upload/epi"
              element={
                <ProtectedRoute>
                  <EpiUpload />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ficha/epi/funcionario"
              element={
                <ProtectedRoute>
                  <FichaEpiLegal />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboards/funcionarios"
              element={
                <ProtectedRoute>
                  <CustoTotalFuncionarios />
                </ProtectedRoute>
              }
            />
            <Route
              path="/hdashboards"
              element={
                <ProtectedRoute>
                  <PaginaInicialDashboards />
                </ProtectedRoute>
              }
            />
            <Route
              path="/posicao/estoque"
              element={
                <ProtectedRoute>
                  <PosicaoDeEstoque />
                </ProtectedRoute>
              }
            />

            <Route
              path="/graficos"
              element={
                <ProtectedRoute>
                  <GraficoDashboard />
                </ProtectedRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </EpiGlobalStorage>
    </div>
  );
};

export default App;
